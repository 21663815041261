import { isProductionEnvironment } from "~/lib/environment";

/* c8 ignore file */
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    gtag: (event: string, action: string, options: unknown) => void;
  }
}

export const GA_TRACKING_ID = isProductionEnvironment() ? "G-3X1N6RBQL9" : "G-7HKZCRZCK1";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const trackPageView = (url: URL) => {
  if (process.env.NODE_ENV === "test") return;

  if (window.gtag)
    window.gtag("config", GA_TRACKING_ID, {
      page_path: url,
    });
};

type GTagEvent = {
  action: string;
  category?: string;
  label?: string;
  value?: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const trackEvent = ({ action, category, label, value }: GTagEvent) => {
  if (process.env.NODE_ENV === "test") return;

  if (window.gtag)
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
};

// https://support.google.com/analytics/answer/7478520?hl=en
export const trackExternalLink = (url: string) => {
  if (process.env.NODE_ENV === "test" || !window.gtag) {
    document.location.href = url;
    return;
  }

  if (window.gtag)
    window.gtag("event", "click", {
      event_category: "outbound",
      event_label: url,
      transport_type: "beacon",
      event_callback: () => {
        document.location.href = url;
      },
    });
};

type HubEventTrackingParams = {
  url: string;
  hubId: string;
  eventId: string;
};
export const trackHubEventClick = ({ hubId, eventId, url }: HubEventTrackingParams) => {
  trackEvent({
    action: "select_event",
    category: hubId,
    label: eventId,
  });

  return trackExternalLink(url);
};
