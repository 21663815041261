"use client";

import { Button, Dialog, Flex, Text } from "@mantine/core";
import { useImpersonation } from "../hooks/impersonation";
import { useMediaQuery } from "@mantine/hooks";
import { IconSpy } from "@tabler/icons-react";

export default function Messages() {
  const isDesktopView = useMediaQuery("(min-width: 768px)");
  const { signOut, isImpersonating, impersonationExpiry, dismissImpersonationDialog } =
    useImpersonation();

  return (
    <>
      {isImpersonating && (
        <Dialog
          opened={!impersonationExpiry || new Date().getTime() > impersonationExpiry}
          onClose={dismissImpersonationDialog}
          radius="md"
          p="xs"
          withBorder
          position={isDesktopView ? { bottom: 10, right: 10 } : { bottom: 65, right: 10 }}
        >
          <Flex align="center" gap={4}>
            <IconSpy size="18" />
            <Text ml={2} flex={1} size="sm">
              Impersonating a user
            </Text>
            <Button size="xs" color="yellow" onClick={signOut}>
              Sign out
            </Button>
            <Button size="xs" variant="default" onClick={signOut}>
              Hide
            </Button>
          </Flex>
        </Dialog>
      )}
    </>
  );
}
