export const SLACK_TOKEN = process.env.SLACK_TOKEN;

export const vercelEnv = () =>
  process.env.VERCEL_ENV ??
  process.env.NEXT_PUBLIC_VERCEL_ENV ??
  process.env.NODE_ENV ??
  "development";

export const standardizedEnvironment = () => vercelEnv().replace("vercel-", "").trim();
export const isProductionEnvironment = () => standardizedEnvironment() === "production";
export const isNonProductionEnvironment = () => !isProductionEnvironment();
export const isDevelopmentEnvironment = () => standardizedEnvironment() === "development";
