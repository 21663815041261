"use client";

import { SWRConfig } from "swr";
import { authorisedFetcher } from "~/utils/fetchers";
import type { JSX } from "react";

export default function SWRProvider({ children }: { children: JSX.Element }) {
  return (
    <SWRConfig value={{ fetcher: authorisedFetcher, refreshInterval: 0 }}>{children}</SWRConfig>
  );
}
