"use client";

import { useEffect } from "react";
import { api } from "~/trpc/react";
import { auth } from "~/lib/firebase";

export const InvalidateTrpcOnAuthStateChange = () => {
  const utils = api.useUtils();

  useEffect(() => {
    return auth().onAuthStateChanged(async () => await utils.invalidate());
  }, [utils.invalidate]);

  return null;
};
