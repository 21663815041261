"use client";

import { useLocalStorage } from "@mantine/hooks";
import { auth } from "../firebase";
import { fetchImpersonationToken } from "~/requests/users";
import { signInWithCustomToken } from "firebase/auth";

export const useImpersonation = () => {
  const [isImpersonating, setImpersonating] = useLocalStorage<boolean>({
    key: "is-impersonating",
  });
  const [impersonationExpiry, setImpersonationExpiry] = useLocalStorage<number>({
    key: "impersonation-dialog-expiry",
  });

  const dismissImpersonationDialog = () => {
    const anHourFromNow = new Date().getTime() + 1000 * 60 * 60;
    setImpersonationExpiry(anHourFromNow);
  };

  const signOut = async () => {
    await signOutWithoutRedirect();
    window.location.assign("/sign-in");
  };

  const signOutWithoutRedirect = async () => {
    await auth().signOut();
    await fetch("/api/logout", { method: "GET", headers: {} });

    setImpersonating(false);
    setImpersonationExpiry(0);
  };

  const impersonateUser = async (user: { id: string }) => {
    const { token, error } = await fetchImpersonationToken(user.id);
    if (!token || error)
      throw new Error("Failed to impersonate user: ${error || 'failed to generate token}");

    await signInWithCustomToken(auth(), token);
    await fetch("/api/logout", { method: "GET", headers: {} });

    setImpersonating(true);
    setImpersonationExpiry(0);
  };

  return {
    impersonateUser,
    signOut,
    signOutWithoutRedirect,
    isImpersonating,
    impersonationExpiry,
    dismissImpersonationDialog,
  };
};
