"use client";

import { useNavigation } from "../navigation";
import posthog from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { useUser } from "reactfire";
import { canonicalUrl } from "~/utils/url_helpers";
import type { JSX } from "react";

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
    api_host: canonicalUrl("/ingest"),
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.opt_out_capturing();
    },
  });
}

export default function PosthogProvider({ children }: { children: JSX.Element }) {
  const posthog = usePostHog();
  const user = useUser();

  useEffect(() => {
    if (user.data?.uid) posthog.identify(user.data?.uid, { email: user.data?.email });
  }, [posthog, user.data?.uid, user.data?.email]);

  useNavigation({
    on: {
      routeChanged: () => posthog?.capture("$pageview"),
    },
  });

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
