import {
  DEFAULT_THEME,
  VariantColorsResolver,
  defaultVariantColorsResolver,
  mergeMantineTheme,
  parseThemeColor,
  rem,
} from "@mantine/core";

export const KiwiTicketColors = {
  primary: "#82c91e",
  error: "#FF6060",
};

const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input);
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  });

  // set default lime button text colour to black
  if (parsedColor.isThemeColor && parsedColor.color === "lime" && input.variant === "filled") {
    return {
      ...defaultResolvedColors,
      color: "var(--mantine-color-black)",
      background: "var(--mantine-color-lime-6)",
      hover: "var(--mantine-color-lime-7)",
    };
  }

  return defaultResolvedColors;
};

export const customerThemeOverrides = {
  fontFamily: "Varela Round, sans-serif",
  primaryColor: "lime",
  variantColorResolver,
  headings: {
    fontWeight: "normal",
    sizes: {
      h1: { fontSize: rem(36) },
      h2: { fontSize: rem(24) },
      h3: { fontSize: rem(20) },
      h4: { fontSize: rem(16) },
    },
  },
  components: {
    Button: {
      defaultProps: {
        variant: "filled",
      },
      styles: {
        root: {
          fontWeight: "normal",
        },
      },
    },
    Card: {
      defaultProps: {
        withBorder: true,
      },
    },
  },
};

export const customerTheme = mergeMantineTheme(DEFAULT_THEME, customerThemeOverrides);
