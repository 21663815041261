import { users } from "@prisma/client";
import { UserRecord } from "firebase-admin/auth";
import { mutate } from "swr";
import { ApiError } from "~/interfaces";
import { authorisedFetcher } from "~/utils/fetchers";
import { canonicalUrl } from "~/utils/url_helpers";

export const fetchUser = async (userId: string) => {
  return await authorisedFetcher<{ user?: users; authUser?: UserRecord; error?: ApiError }>(
    `/api/admin/users/${userId}`,
  );
};

export const fetchImpersonationToken = async (userId: string) => {
  return await authorisedFetcher<{ token?: string; error?: ApiError }>(
    `/api/admin/users/${userId}/impersonate`,
  );
};

export const verifyUserAccount = async (userId: string) => {
  const result = await authorisedFetcher<{ error?: ApiError }>(
    `/api/admin/users/${userId}/verify`,
    {
      method: "POST",
    },
  );

  mutate(`/api/admin/users/${userId}`);
  return result;
};

export const checkUserVerification = async (email: string) => {
  const response = await fetch(
    canonicalUrl(`/api/auth/check_user_verified?email=${encodeURIComponent(email)}`),
  );
  return (await response.json()) as { found: boolean; verified: boolean };
};
