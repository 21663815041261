const baseUrl = (useFixedDomain = true) => {
  switch ((process.env.NEXT_PUBLIC_VERCEL_ENV ?? process.env.NODE_ENV)?.toLocaleLowerCase()) {
    case "development":
      return "http://localhost:3000";
    case "preview":
      if (process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF === "main" && useFixedDomain)
        return "https://uat.kiwiticket.co.nz";
      return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
    default:
      return useFixedDomain
        ? "https://kiwiticket.co.nz"
        : `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }
};

export const origin = () =>
  canonicalUrl()
    .replace(/https?:\/\//, "")
    .replace(/\/$/, "");

export const canonicalUrl = (
  relativeUrl = "/",
  { base, useAbsoluteUatUrl }: { base?: string; useAbsoluteUatUrl?: boolean } = {
    useAbsoluteUatUrl: true,
  },
) => new URL(relativeUrl, base ?? baseUrl(useAbsoluteUatUrl)).toString();

export const continueUrl = (url: string) => {
  if (url.startsWith("http")) return url;
  return canonicalUrl(url);
};
