import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { GA_TRACKING_ID } from "~/utils/gtag";

const firebasePublicConfig = process.env.NEXT_PUBLIC_FIREBASE
  ? JSON.parse(process.env.NEXT_PUBLIC_FIREBASE)
  : undefined;

const app = initializeApp({
  apiKey: firebasePublicConfig.apiKey,
  authDomain: firebasePublicConfig.authDomain,
  databaseURL: firebasePublicConfig.databaseUrl,
  projectId: firebasePublicConfig.projectId,
  appId: firebasePublicConfig.appId,
  measurementId: GA_TRACKING_ID,
});

export const auth = () => getAuth(app);
export const firestore = () => getFirestore(app);
export const functions = () => getFunctions(app);
export const aussieFunctions = () => getFunctions(app, "australia-southeast1");
