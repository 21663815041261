import { captureException } from "@sentry/nextjs";
import { User } from "firebase/auth";
import { ApiError } from "~/interfaces";
import { logException } from "~/lib/exceptions";
import { auth } from "~/lib/firebase";

const getCurrentUser = () =>
  new Promise<User | null>((resolve, reject) => {
    const unsubscribe = auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });

export const authorisedFetch = async (resource: RequestInfo, init?: RequestInit | undefined) => {
  const user = await getCurrentUser();
  const token = await user?.getIdToken();

  if (token) {
    return await fetch(resource, {
      ...init,
      headers: { ...init?.headers, authorization: `Bearer ${token}` },
    });
  }

  return await fetch(resource, init);
};

type FetchResponse<T> = T & { error?: ApiError; status: number };
const genericError: ApiError = {
  code: "request_failed",
  message: "Something went wrong. We've been notified and are looking into the issue.",
};
export const authorisedFetcher = async <T>(
  resource: RequestInfo,
  init?: RequestInit | undefined,
): Promise<FetchResponse<T>> =>
  await authorisedFetch(resource, init)
    .then(async (res) => {
      const contentType = res.headers.get("Content-Type");

      if (res.ok || res.status === 200) {
        if (contentType?.startsWith("application/json")) {
          const jsonContent = await res.json();
          return { status: res.status, ...jsonContent };
        }
        return { status: res.status };
      }

      if (contentType?.startsWith("application/json")) {
        const jsonContent = await res.json();
        return { status: res.status, error: genericError, ...jsonContent };
      }

      if (res.status !== 401) {
        const responseData = await res.text();
        console.error(responseData);
        captureException(
          new Error(`Request to ${resource} failed with status ${res.status}: ${responseData}`),
          { extra: { responseData } },
        );
      }

      return { status: res.status, error: genericError };
    })
    .catch(async (error) => {
      await logException(error);
      return {
        status: 500,
        error: genericError,
      };
    });
